<template>
  <el-empty :description="title"></el-empty>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: "暂无数据",
      },
    },
  };
</script>

<style></style>
